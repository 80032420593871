import React from "react";

import { RouteComponentProps } from "@reach/router";

const NotFoundPage = (_props: RouteComponentProps) => (
  <div>
    <h1>404: Not Found</h1>
    <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
  </div>
);

export default NotFoundPage;
